.search-box {
    box-shadow:none !important;
}

.article.card {
    border-radius: $rounded-borders;
    text-decoration: none!important;
    padding: 15px;
    height: 100%;
    
    .card-footer {
        font-size: 14px;
        margin: 0 -15px -15px -15px;
        padding: 15px 35px;
    }
}

.article {

    .article-metadata {
        font-size: 14px;
        margin-top: -6px;
    }

    .tag:hover {
        background-color: $primary;
        color: white !important;
    }

    footer {
        font-size: 10px;
        color: #6c757d;
        text-decoration: underline;
        text-decoration-color: yellow;
        text-align: right;
        margin-top: 4em;
        margin-right: 2em;
    }

    
    h1, h2 { margin-top: 32px; }

    hr { background: $secondary; }

    img {
        display: block;
        max-width: 100%;
        height: auto;    
        margin: 1rem auto;
    }

    .video {
        position: relative;
        padding-bottom: 56.25%;
        margin-bottom: 1rem;
        width: 100%;
        iframe {
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    a:not(.btn):not([class^="carousel-"]):not([class^="list-"]):not(.no-underline)  {
        display: inline-block;
        text-decoration: none;

        &:hover { color: $primary; }

        &::after {
            content: '';
            display: block;
            height: .15em;
            margin-top: -.15em;
            width: 0;
            background: $primary;
            transition: width .35s;
        }

        &:hover::after { width: 100%; }
    }

    pre {
        background: $light;
        border: 1px solid #ddd;
        color: $dark;
        font-family: monospace;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 1.6em;
        max-width: 100%;
        padding: 1em 1.5em;
        display: block;
        page-break-inside: avoid;
        overflow: auto;
        word-wrap: break-word;
    }

    code.highlighter-rouge {
        background-color: $light;
        color: $dark;
        border-radius: 3px;
        margin: 0;
        padding: .2em .65em;
    }

    blockquote {
        border-left: .25em solid $primary;
        color: $secondary;
        padding: 0 1em;
    }
      
    table {
        display: block;
        overflow-x: auto;
        margin: 1rem 0;

        td {
            border: 1px solid #ddd;
            padding: 8px 16px;
        }

        th {
            @extend td;
            padding-top: 12px;
            padding-bottom: 12px;
            font-weight: 500;
            text-align: left;
            background-color: $primary;
            color: white;
        }

        tr:nth-child(even) {
            background-color: $light;
        }
    }
}