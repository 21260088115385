.project.card {
    border-radius: $rounded-borders;
    text-decoration: none!important;
    margin: 10px auto;

    .card-img-top {
        border-radius: $rounded-borders $rounded-borders 0 0;
    }

    &:hover:not(.article) {
        box-shadow: -2px 8px 40px -12px rgba(0, 0, 0, .24);
    }
}